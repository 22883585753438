import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const targetDate = new Date("2024-09-15"); // Target launch date
    const calculateDaysLeft = () => {
      const today = new Date();
      const difference = targetDate - today;
      const days = Math.ceil(difference / (1000 * 60 * 60 * 24));
      setDaysLeft(days);
    };

    calculateDaysLeft();
    const intervalId = setInterval(calculateDaysLeft, 86400000); // Update every day

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="text-center">
        <h2 className="text-4xl font-bold text-red-600 mb-4">Dashboard</h2>
        <p className="text-2xl text-red-600 mb-8">
          Launching Soon this September
        </p>
        <p className="mt-8 text-xl text-gray-700">
          {daysLeft > 0
            ? `${daysLeft} days left until launch!`
            : "Launching today!"}
        </p>
        <div className="mt-4">
          <Link to="/institutions" className="text-red-600 underline">
            Go back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
