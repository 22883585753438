import "../App.css";
import Button from "../customComponents/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import EnquiryCardComponent from "../customComponents/EnquiryCard";
import { post } from "../utils/api";
import ConfirmationModal from "../customComponents/ConfirmationModal";
import noImg from "../assets/noImg.png";
import { toast, ToastContainer } from "react-toastify";

const InstitutionDetails = () => {
  const location = useLocation();
  const institution = location.state;
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Open Enquiry");
  const [enquiries, setEnquiries] = useState({
    "Open Enquiry": [],
    "Confirmed Enquiry": [],
    "Closed Enquiry": [],
    "Cancelled Enquiry": [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const insDetailsFlag = true;

  const handleNavigateIns = () => {
    navigate("/add-institutions", { state: { institution, insDetailsFlag } });
  };

  const handleNavigateEnquiry = () => {
    navigate("/add-enquiry", { state: { institution } });
  };

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const payload = {};
        const response = await post("/getAllInstitutionsEnquiry", payload);
        const data = response.data;

        // Check if institution is defined and has a matching institutionid
        if (!institution) {
          return; // No institution provided, do not proceed
        }

        const filteredData = data.filter(
          (enquiry) => enquiry.institutionid === institution.institutionid
        );

        const enquiriesByStatus = {
          "Open Enquiry": [],
          "Confirmed Enquiry": [],
          "Closed Enquiry": [],
          "Cancelled Enquiry": [],
        };

        filteredData.forEach((enquiry) => {
          const enquiryData = {
            image: "/src/assets/dummyEquipImg.png",
            enquiryname: enquiry?.enquiryname,
            enquirystatusid: enquiry?.enquirystatusid,
            enquiryid: enquiry?.enquiryid,
            dateoftravel: enquiry?.dateoftravel,
            nextfollowupdate: enquiry?.nextfollowupdate,
            noofdays: enquiry?.noofdays,
            noofnight: enquiry?.noofnight,
            noofpax: enquiry?.noofpax,
            enquirysource: enquiry?.enquirysource,
            destination: enquiry?.destination,
            referencecomment: enquiry?.referencecomment,
            referenceid: enquiry?.referenceid,
            accommodationtype: enquiry?.accommodationtype,
            accommodationtypeid: enquiry?.accommodationtypeid,
            hoteltype: enquiry?.hoteltype,
            hoteltypeid: enquiry?.hoteltypeid,
            enquirycomments: enquiry?.enquirycomments,
            onward: enquiry?.onwardtrn,
            onwardId: enquiry?.onwardtrnid,
            localtrn: enquiry?.localtrn,
            localtrnid: enquiry?.localtrnid,
            return: enquiry?.returntrn,
            returnId: enquiry?.returntrnid,
            prices: enquiry?.prices,
            packageType: enquiry?.packagetype,
            packageTypeId: enquiry?.packagetypeid,
            name: enquiry?.name,
          };

          switch (enquiry.enquirystatusid) {
            case 1:
              enquiriesByStatus["Open Enquiry"].push(enquiryData);
              break;
            case 2:
              enquiriesByStatus["Confirmed Enquiry"].push(enquiryData);
              break;
            case 3:
              enquiriesByStatus["Closed Enquiry"].push(enquiryData);
              break;
            case 4:
              enquiriesByStatus["Cancelled Enquiry"].push(enquiryData);
              break;
            default:
              break;
          }
        });

        setEnquiries(enquiriesByStatus);
      } catch (error) {
        console.error("Failed to fetch enquiries:", error);
      }
    };

    fetchEnquiries();
  }, [institution]);

  const handleConfirmDelete = async () => {
    try {
      const payload = { institutionid: institution.institutionid };
      await post("/deleteInstitution", payload);
      setIsModalOpen(false);
      toast.success(
        `Institution "${institution.name}" has been successfully deleted.`
      );

      setTimeout(() => {
        navigate("/institutions");
      }, 1000); // Delay navigation by 1 seconds
    } catch (error) {
      console.error("Failed to delete institution:", error);
      toast.error("Failed to delete the institution. Please try again.");
    }
  };

  if (!institution) {
    return <div>No institution data available</div>;
  }

  const renderTabContent = () => {
    return enquiries[selectedTab].map((enquiry, index) => {
      return (
        <EnquiryCardComponent
          key={index}
          enquiry={enquiry}
          institution={institution}
        />
      );
    });
  };

  return (
    <div className="">
      <div className="block md:block lg:flex justify-between">
        <div className="mb-6 md:mb-0">
          <h2 className="text-primary-black text-[24px] font-semibold">
            {institution.name}
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Institution</span> /
            <span className="font-regular"> {institution.name} </span>
          </p>
        </div>
        <div className="text-right">
          {(user?.role === "Admin" || user?.role === "Sales Manager") && (
            <>
              <Button
                type="tertiaryClasses"
                label="Delete Institution"
                onClick={handleDelete}
              />
              <Button
                type="secondary"
                label="Edit Institution"
                className=""
                onClick={handleNavigateIns}
              />
            </>
          )}
          <Button
            type="primary"
            label="Add Enquiry"
            className=""
            onClick={handleNavigateEnquiry}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title="Delete Institution"
        message={`Are you sure you want to delete the institution "${institution.name}"?`}
      />
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 items-center mt-6">
        {/* Institution Details Display */}
        <div className="col-span-1 grid grid-cols-1 lg:grid-cols-2 bg-white p-6 rounded-lg shadow-md flex-wrap justify-between items-center md:h-auto lg:h-60">
          <div>
            <img
              src={institution.logo || noImg}
              className="w-20 md:w-28 lg:w-36"
              alt="Logo"
            />
          </div>
          <div className="mt-4">
            <h1 className="text-[16px] md:text-[22px] font-bold text-[#121212]">
              {institution.name}
            </h1>
            <h4 className="text-[14px] md:text-[18px] font-normal text-[#121212] py-2">
              {institution.city}, {institution.state}
            </h4>
            <p className=" text-gray-500 py-2">
              <span className="material-symbols-outlined bg-gray-200 text-gray-500 rounded text-[12px] md:text-[16] p-2 mr-4">
                location_on
              </span>
              <span className="break-normal md:break-all md:max-w-xs leading-tight">
                {" "}
                {institution.address.split(",").map((line, index) => (
                  <span key={index} className="text-[14px] md:text-[16px]">
                    {line}
                  </span>
                ))}
              </span>
            </p>
          </div>
        </div>

        {/* Primary and Secondary Contact Display */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white p-6 rounded-lg shadow-md md:h-auto lg:h-60 ">
          <div className="col-span-1 md:border-r-2 pr-4">
            <h4 className="text-[16px] font-semibold text-gray-500 bg-gray-200 rounded p-2 mb-2">
              Primary Contact
            </h4>
            <p className="text-[16px] font-semibold leading-8 text-black">
              {institution.primary_name}
            </p>
            <p className="text-[16px] leading-8 mb-1 text-gray-500 ">
              {institution.primary_designation}
            </p>
            <p className="text-[16px] leading-10 text-black ">
              <span className="flex items-center">
                <span className="material-symbols-outlined bg-gray-200 text-gray-500 rounded text-sm p-1 mr-4">
                  smartphone
                </span>{" "}
                {institution.primary_mobile_number}
              </span>
              <span className="flex items-center">
                <span className="material-symbols-outlined bg-gray-200 text-gray-500 rounded text-sm p-1 mr-4">
                  mail
                </span>{" "}
                <span className="break-normal md:break-all md:max-w-xs leading-tight">
                  {institution.primary_email}
                </span>
              </span>
            </p>
          </div>
          <div className="col-span-1  pr-4">
            <h4 className="text-16 font-semibold text-gray-500 bg-gray-200 rounded p-2 mb-2">
              Secondary Contact
            </h4>
            {institution.secondary_name ||
            institution.secondary_email ||
            institution.secondary_mobile_number ||
            institution.secondary_designation ? (
              <div>
                <p className="text-16 font-semibold leading-8 text-black">
                  {institution.secondary_name}
                </p>
                <p className="text-16 leading-8 mb-1 text-gray-500">
                  {institution.secondary_designation}
                </p>
                <p className="text-16 leading-10 text-black">
                  <span className="flex items-center">
                    <span className="material-symbols-outlined bg-gray-200 text-gray-500 rounded text-sm p-1 mr-4">
                      smartphone
                    </span>{" "}
                    {institution.secondary_mobile_number}
                  </span>
                  <span className="flex items-center">
                    <span className="material-symbols-outlined bg-gray-200 text-gray-500 rounded text-sm p-1 mr-4">
                      mail
                    </span>{" "}
                    <span className="break-normal md:break-all md:max-w-xs leading-tight">
                      {institution.secondary_email}
                    </span>
                  </span>
                </p>
              </div>
            ) : (
              <div className="text-center mt-10">
                <span className="material-symbols-outlined text-4xl text-gray-500">
                  sentiment_dissatisfied
                </span>
                <p className="text-14 leading-5 text-gray-500 mt-2">
                  No Contact Found
                </p>
              </div>
            )}
          </div>
          {/* <div className="col-span-1 text-center">
            <h4 className="text-[16px] font-semibold text-gray-500 bg-gray-200 rounded p-2 mb-2">
              Secondary Contact
            </h4>
            {institution.secondary_name ? (
              <p className="text-[16px] leading-10 text-black mt-7">
                {institution.secondary_name},<br />
                {institution.secondary_designation},<br />
                {institution.secondary_email}
                <br />
                {institution.secondary_mobile_number}
              </p>
            ) : (
              <div className="mt-7">
                <span className="material-symbols-outlined text-4xl text-gray-500">
                  sentiment_dissatisfied
                </span>
                <p className="text-[14px] leading-5 text-gray-500 mt-2">
                  No Contact Found
                </p>
              </div>
            )}
          </div> */}
        </div>
      </div>

      {/* Pills Tabs View */}
      <div className="flex justify-center mt-8">
        <div className="rounded-lg p-2 sm:overflow-visible overflow-auto whitespace-nowrap">
          {[
            "Open Enquiry",
            "Confirmed Enquiry",
            "Closed Enquiry",
            "Cancelled Enquiry",
          ].map((tab) => (
            <button
              key={tab}
              className={`px-4 py-2 mb-2 md:mb-0 rounded-lg hover:outline-none focus:outline-none ml-4 text-[14px] md:text-[16px] ${
                selectedTab === tab
                  ? "bg-[#D34B44] text-white"
                  : "bg-gray-200 text-black"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <ToastContainer />
      {/* Dynamic Tab Content */}
      <div className="mt-8 space-y-4">{renderTabContent()}</div>
    </div>
  );
};

export default InstitutionDetails;
