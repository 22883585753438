import React, { useState } from "react";

const DatePicker = ({ selectedDate, onDateChange, label }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  const handleDateClick = (date) => {
    onDateChange(date);
    setIsCalendarOpen(false);
  };

  const renderCalendar = () => {
    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
    const firstDayIndex = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    ).getDay();
    const prevLastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    ).getDate();

    const dates = [];
    for (let x = firstDayIndex; x > 0; x--) {
      dates.push(
        <div key={`prev-${x}`} className="text-gray-400 text-center p-2">
          {prevLastDay - x + 1}
        </div>
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      dates.push(
        <div
          key={i}
          className={`cursor-pointer text-center p-2 rounded ${
            selectedDate.getDate() === i &&
            selectedDate.getMonth() === currentDate.getMonth()
              ? "bg-blue-500 text-white  p-3 rounded-lg "
              : ""
          }`}
          onClick={() =>
            handleDateClick(
              new Date(currentDate.getFullYear(), currentDate.getMonth(), i)
            )
          }
        >
          {i}
        </div>
      );
    }

    return dates;
  };

  return (
    <div className="relative">
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <input
        type="text"
        readOnly
        value={selectedDate?.toDateString()}
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        className="w-full px-4 py-2 pr-10 border border-gray-300 rounded"
      />
      <span
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        className="absolute right-3 top-[8px] cursor-pointer text-gray-400 material-symbols-outlined"
      >
        calendar_month
      </span>
      {isCalendarOpen && (
        <div className="absolute bottom-full left-0 mb-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
          <div className="flex justify-between items-center p-2">
            <button
              onClick={() =>
                setCurrentDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() - 1,
                    1
                  )
                )
              }
              className="focus:outline-none"
            >
              &#8249;
            </button>
            <span>{`${currentDate.toLocaleString("default", {
              month: "long",
            })} ${currentDate.getFullYear()}`}</span>
            <button
              onClick={() =>
                setCurrentDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() + 1,
                    1
                  )
                )
              }
              className="focus:outline-none"
            >
              &#8250;
            </button>
          </div>
          <div className="grid grid-cols-7 gap-1 p-2">
            <div className="font-bold text-center">Su</div>
            <div className="font-bold text-center">Mo</div>
            <div className="font-bold text-center">Tu</div>
            <div className="font-bold text-center">We</div>
            <div className="font-bold text-center">Th</div>
            <div className="font-bold text-center">Fr</div>
            <div className="font-bold text-center">Sa</div>
            {renderCalendar()}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
