import { useState } from "react";

const SearchComponent = ({ onChange }) => {
  const [query, setQuery] = useState("");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    onChange(event.target.value); // Pass the query to parent component
  };

  return (
    <div className="flex items-center rounded-md">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        className="flex-grow outline-none p-2 rounded-l-lg"
        placeholder="Search"
      />
      <button className="p-2 rounded-none h-[40px] rounded-r">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.9 14.32a8 8 0 111.414-1.415l4.382 4.383a1 1 0 11-1.415 1.414l-4.382-4.383zM8 14A6 6 0 108 2a6 6 0 000 12z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default SearchComponent;
