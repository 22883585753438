import { useNavigate } from "react-router-dom";

const InstitutionModal = ({ show, onClose, enquiry }) => {
  const navigate = useNavigate();
  const isEditDisabled = enquiry.enquirystatusid !== 1;

  const handleEditClick = () => {
    if (!isEditDisabled) {
      navigate("/add-enquiry", { state: { enquiry } });
    }
  };
  // const handleEditClick = () => {
  //   if (!isEditDisabled) {
  //     const institutionId = institution?.institutionid;
  //     const editenquiry = true;
  //     navigate("/add-enquiry", {
  //       state: { enquiry, institutionId, editenquiry },
  //     });
  //   }
  // };
  const statusMapping = {
    1: { label: "Open", style: "bg-red-500" },
    2: { label: "Confirmed", style: "bg-green-800" },
    3: { label: "Closed", style: "bg-gray-700" },
    4: { label: "Canceled", style: "bg-blue-800" },
  };

  const currentStatus = statusMapping[enquiry.enquirystatusid];

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end transition-transform transform ${
        show ? "translate-y-0" : "translate-y-full"
      }`}
      onClick={onClose}
    >
      <div
        className="bg-white w-full p-6 rounded-t-lg shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="block md:flex justify-between items-center border-b pb-3">
          <div>
            <h2 className="text-2xl font-semibold">{enquiry.name}</h2>
            <p className="mt-2 md:mt-2 mb-4 md:mb-0 text-[16px] w-full">
              <span className="text-gray-500">Status</span> /
              <a
                className={`${currentStatus.style} ml-3 text-white font-semibold py-1 px-1 rounded shadow`}
              >
                {currentStatus.label}
              </a>
            </p>
            {/* {"Need to fix the Enquiry Status"} */}
            {/* <Select
            options={"id"}
            onChange={(e) => {console.log(e)}}
            /> */}
          </div>
          {enquiry?.prices || enquiry?.nextfollowupdate ? (
            <div className="text-end">
              {enquiry.enquirystatusid === 2 && enquiry?.prices && (
                <span className="font-open-sans text-2xl font-bold leading-8 text-left">
                  {"₹"} {enquiry.prices}{" "}
                  <span className="text-base font-normal leading-6">
                    / Person
                  </span>
                </span>
              )}
              {enquiry.enquirystatusid === 1 && enquiry?.nextfollowupdate && (
                <span className="text-base font-normal leading-6 mt-2">
                  <span className="font-bold">Follow up Date: </span>
                  {new Date(enquiry.nextfollowupdate).toLocaleDateString()}
                </span>
              )}
              <a
                onClick={onClose}
                className="text-gray-500 hover:text-black ml-5 h-[40px] cursor-pointer"
              >
                <span className="material-symbols-outlined  relative bottom-[80px] md:bottom-[30px] md:right-0">close</span>
              </a>
            </div>
          ) : (
            <div className="text-end">
              <a
                onClick={onClose}
                className="text-gray-500 hover:text-black ml-5 h-[40px] cursor-pointer"
              >
                <span className="material-symbols-outlined">close</span>
              </a>
            </div>  
          )}
        </div>
        <div className=" h-[22rem] md:h-[24rem] overflow-y-auto">
          <div className="block md:flex gap-4 p-4">
            <div className="grid w-full lg:w-3/4 h-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="col-span-1 bg-[#EFEFF0]  border-[#EFEFF0] border-solid border rounded-xl p-4">
                <p className="text-[16px] bg-[#EFEFF0] font-semibold text-[#121212] mb-2 uppercase">
                  Enquiry Name
                </p>
                <p className="text-[16px] font-normal text-[#121212]">
                  {enquiry?.enquiryname}
                </p>
              </div>
              <div className="col-span-1 bg-[#EFEFF0] border-[#EFEFF0] border-solid border rounded-xl p-4">
                <p className="text-[16px] font-semibold text-[#121212] mb-2 uppercase">
                  Enquiry Status
                </p>
                <p className="text-[16px] font-normal text-[#121212]">Open</p>
              </div>
              <div className="col-span-1 bg-[#EFEFF0]  border-[#EFEFF0] border-solid border rounded-xl p-4">
                <p className="text-[16px] font-semibold text-[#121212] mb-2 uppercase">
                  Package Type
                </p>
                <p className="text-[16px] font-normal text-[#121212]">
                  {enquiry?.packageType}
                </p>
              </div>
            </div>
            <div className="grid w-full lg:w-1/2 h-full grid-cols-1 bg-[#EFEFF0]  border-[#EFEFF0] border-solid border rounded-xl p-4 mt-5 md:mt-0">
              <div className="col-span-1">
                <p className="text-[16px] font-semibold text-[#121212] mb-2 uppercase">
                  Destination reference Comment
                </p>
                <p className="text-[16px] leading-5 font-normal text-[#121212]">
                  {enquiry?.referencecomment}
                </p>
              </div>
            </div>
          </div>
          <div className="block md:flex gap-4 p-4 ">
            <div className="grid w-full lg:w-3/4 h-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="col-span-1 border-[#EFEFF0] border-solid border rounded-xl p-4">
                <p className="text-[16px] leading-5 font-semibold text-[#121212] mb-2 uppercase">
                  Source
                </p>
                <div className="flex items-center mb-8">
                  <span className="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    signpost
                  </span>
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.enquirysource}
                  </p>
                </div>

                <p className="text-[16px] font-semibold text-[#121212] mb-2 uppercase">
                  DESTINATION
                </p>

                <div className="flex items-center mb-8">
                  <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    signpost
                  </span>
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.destination}
                  </p>
                </div>
              </div>
              <div className="col-span-1 border-[#EFEFF0] border-solid border rounded-xl p-4">
                <p className="text-[16px] font-semibold text-[#121212] mb-2">
                  HOTEL TYPE
                </p>
                <div className="flex items-center mb-8">
                  <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    star
                  </span>
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.hoteltype}
                  </p>
                </div>
                <p className="text-[16px] font-semibold text-[#121212] mb-2">
                  ACCOMODATION TYPE
                </p>
                <div className="flex items-center mb-8">
                  <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    bed
                  </span>
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.accommodationtype}
                  </p>
                </div>
                <p className="text-[16px] font-semibold text-[#121212] mb-2">
                  REFERENCE PACKAGE ID
                </p>
                <div className="flex items-center mb-8">
                  <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    bed
                  </span>
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.referenceid}
                  </p>
                </div>
              </div>
              <div className="col-span-1 border-[#EFEFF0] border-solid border rounded-xl p-4">
                <p className="text-[16px] font-semibold text-[#121212] mb-2">
                  ONWARD TRANSPORTATION
                </p>
                <div className="flex items-center mb-8">
                  {/* <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    directions_bus
                  </span> */}
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.onward}
                  </p>
                </div>
                <p className="text-[16px] font-semibold text-[#121212] mb-2">
                  RETURN TRANSPORTATION
                </p>
                <div className="flex items-center mb-8">
                  {/* <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    travel
                  </span> */}
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.return}
                  </p>
                </div>
                <p className="text-[16px] font-semibold text-[#121212] mb-2">
                  LOCAL TRANSPORTATION
                </p>
                <div className="flex items-center mb-8">
                  {/* <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    travel
                  </span> */}
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.localtrn}
                  </p>
                </div>
              </div>
              <div className="col-span-1 lg:col-span-3 border-[#EFEFF0] border-solid border rounded-xl p-4">
                <p className="text-[16px] font-semibold text-[#121212] mb-2">
                  COMMENTS
                </p>
                <p className="w-full text-[16px] font-normal leading-5 text-[#121212]">
                  {enquiry?.enquirycomments}
                </p>
              </div>
            </div>
            <div className="grid w-full lg:w-1/2 h-full grid-cols-1 border-[#EFEFF0] border-solid border rounded-xl p-4">
              <div className="col-span-1">
                <p className="text-[16px] font-semibold text-[#121212] mb-2 uppercase">
                  NUMBER OF PAX
                </p>
                <div className="flex items-center mb-10">
                  <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    group
                  </span>
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.noofpax}
                  </p>
                </div>
                <p className="text-[16px] font-semibold text-[#121212] mb-2 uppercase">
                  NUMBER OF DAYS
                </p>
                <div className="flex items-center mb-10">
                  <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    sunny
                  </span>
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.noofdays}
                  </p>
                </div>
                <p className="text-[16px] font-semibold text-[#121212] mb-2 uppercase">
                  NUMBER OF NIGHTS
                </p>
                <div className="flex items-center mb-10">
                  <span class="material-symbols-outlined text-[#D34B44] text-[16px] leading-5 font-normal text-custom-[#D34B44] rounded text-md mr-2">
                    dark_mode
                  </span>
                  <p className="text-[16px] font-normal text-[#121212]">
                    {enquiry?.noofnight}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex pt-7 px">
          <a
            onClick={onClose}
            href=""
            className="w-full text-center flex-grow bg-[#EFEFF0] text-black font-semibold py-3 px-4 hover:text-black"
          >
            CLOSE
          </a>
          <a
            className={`w-full text-center flex-grow bg-[#D34B44] text-white font-semibold py-3 px-4 ${
              isEditDisabled
                ? "opacity-50 cursor-not-allowed"
                : "hover:text-white"
            }`}
            onClick={handleEditClick}
            aria-disabled={isEditDisabled}
          >
            EDIT DETAILS
          </a>
        </div>
      </div>
    </div>
  );
};

export default InstitutionModal;
