import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import Upload from "../customComponents/Upload";
import Loader from "../widgets/Loader";
import { post } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, type } = location.state || {};
  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    username: "",
    password: "",
    role: "",
    email: "",
    mobilenumber: "",
    assigncity: "",
    profilephoto: null,
    isactive: true,
    type: type || "insert",
    userid: user?.userid || null,
  });

  const handleSelectChange = (value, name) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      // Allow only valid email characters
      const validEmailChars = /^[a-zA-Z0-9._%+-@]*$/;
      if (validEmailChars.test(value)) {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (name === "mobilenumber") {
      // Allow only numeric input for mobile number
      const validNumberChars = /^[0-9]*$/;
      if (validNumberChars.test(value)) {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (user) {
      setFormState({
        ...user,
        password: "", // Clear password for security reasons
        type: "update",
        role: { value: user.role, label: user.role }, // Assuming your Select component expects this format
        assigncity: { value: user.assigncity, label: user.assigncity }, // Same as above
        isactive: user.isactive ?? true, // Ensure isactive is set correctly
      });
    }
  }, [user]);

  const handleFileSelect = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormState((prevState) => ({
        ...prevState,
        profilephoto: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  const validateInput = (data) => {
    const errors = {};

    if (!data.username || data.username.trim() === "") {
      errors.username = "Username is required.";
    }

    if (!data?.assigncity?.value || data?.assigncity?.value.trim() === "") {
      errors.password = "City is required.";
    }
    if (!data?.role?.value || data?.role?.value.trim() === "") {
      errors.password = "Designation is required.";
    }

    if (!data.email || data.email.trim() === "") {
      errors.email = "Email is required.";
    }

    if (!data.mobilenumber || data.mobilenumber.trim() === "") {
      errors.mobilenumber = "Mobile number is required.";
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors,
    };
  };

  const handleSave = async () => {
    setLoading(true); // Show loader

    try {
      const payload = {
        ...formState,
        assigncity: formState?.assigncity?.value,
        role: formState?.role?.value,
        type: formState.userid ? "update" : "insert",
        isactive: formState.isactive || true, // Ensure isactive is set correctly
      };

      const validationResult = validateInput(formState);

      if (validationResult.isValid) {
        await post("/addUser", payload);
        toast.success("User has been successfully added.");

        // Delay navigation to allow toast to be visible
        setTimeout(() => {
          navigate("/users");
        }, 1000); // 1 second delay
      } else {
        toast.error("Please fill all the mandatory fields."); // Show toast error message
      }
    } catch (error) {
      toast.error("An error occurred while adding the user."); // Show toast error message for any exceptions
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/* Your actual content here */}
          <div className="flex justify-between">
            <div>
              <h2 className="text-primary-black text-[24px] font-semibold">
                Add User
              </h2>
              <p className="text-[12px]">
                <span className="font-bold">Users</span> /{" "}
                <span className="font-regular">Add User</span>
              </p>
            </div>
            <div className="flex gap-4 mt-4 md:mt-0 justify-end">
              <Button type="primary" label="Save" onClick={handleSave} />
              <Button
                type="secondary"
                label="Cancel"
                onClick={handleNavigateBack}
              />
            </div>
          </div>

          <Paper className="my-8 py-12 border">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <Upload onFileSelect={handleFileSelect} />
              </div>
              <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    User Name <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="username"
                    value={formState.username}
                    onChange={handleInputChange}
                    placeholder="User Name"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Designation <span className="text-red-500">*</span>
                  </label>
                  <Select
                    name="role" // Ensure this matches your backend structure for designation
                    value={formState.role}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "role")
                    }
                    options={[
                      { id: 1, value: "Sales Manager", label: "Sales Manager" },
                      {
                        id: 2,
                        value: "Sales Executive",
                        label: "Sales Executive",
                      },
                    ]}
                    placeholder="Select Designation"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Mobile Number <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="mobilenumber"
                    value={formState.mobilenumber}
                    onChange={handleInputChange}
                    placeholder="Mobile Number"
                  />
                </div>
                <div>
                  <label className="text-[12px] font-semibold mb-2">
                    Assign Location <span className="text-red-500">*</span>
                  </label>
                  <Select
                    name="assigncity"
                    value={formState.assigncity}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "assigncity")
                    }
                    options={[
                      { id: 1, value: "Banashankari", label: "Banashankari" },
                      {
                        id: 2,
                        value: "Electronic City",
                        label: "Electronic City",
                      },
                      { id: 3, value: "Hebbal", label: "Hebbal" },
                      { id: 4, value: "HSR Layout", label: "HSR Layout" },
                      { id: 5, value: "Indiranagar", label: "Indiranagar" },
                      { id: 6, value: "Jayanagar", label: "Jayanagar" },
                      { id: 7, value: "JP Nagar", label: "JP Nagar" },
                      { id: 8, value: "Koramangala", label: "Koramangala" },
                      { id: 9, value: "Malleshwaram", label: "Malleshwaram" },
                      { id: 10, value: "Marathahalli", label: "Marathahalli" },
                      { id: 11, value: "MG Road", label: "MG Road" },
                      { id: 12, value: "Rajajinagar", label: "Rajajinagar" },
                      { id: 13, value: "RT Nagar", label: "RT Nagar" },
                      { id: 14, value: "Whitefield", label: "Whitefield" },
                      { id: 15, value: "Yeshwanthpur", label: "Yeshwanthpur" },
                    ]}
                    placeholder="Select Location"
                  />
                </div>
              </div>
            </div>
          </Paper>
        </div>
      )}
      {/* <Paper className="my-8 py-12 border">
        <h2 className="text-[#121212] text-[20px] leading-8 font-semibold mb-5">
          Change Password
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="text-left mb-4">
              <label className=" text-[14px] md:text-[16px] font-semibold">
                Old Password
              </label>
              <Password
                type="password"
                placeholder="Password"
                className="border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm border-b-[#797979]"
              />
            </div>

            <div>
              <label className=" text-[14px] md:text-[16px] font-semibold">
                New Password
              </label>
              <Password
                type="password"
                placeholder="Password"
                className="border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm border-b-[#797979]"
              />
            </div>
            <div>
              <label className=" text-[14px] md:text-[16px] font-semibold ">
                Confirm Password
              </label>
              <Password
                type="password"
                placeholder="Password"
                className="border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm border-b-[#797979]"
              />
            </div>
          </div>
        </div>
      </Paper> */}
      <ToastContainer />
    </div>
  );
};

export default AddUser;
