import React, { useEffect, useState } from "react";
import Table from "../customComponents/Table";
import Paper from "../widgets/Paper";
import { get } from "../utils/api";

const AllUser = () => {
  const [data, setData] = useState([]);

  const columns = [
    { id: "id", header: "ID", cell: "ID" },
    { id: "username", header: "Name", cell: "username" },
    { id: "email", header: "Email", cell: "email" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await get("/getAllUser");
        setData(result.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[28px] font-semibold">
            All User
          </h2>
          <p className="text-[14px]">
            <span className="font-bold">Users </span> /
            <span className="font-regular">All User</span>
          </p>
        </div>

        {/* <div className="flex gap-4">
          <Button
            type="primary"
            label="Save"
            onClick={() => alert("Save Button Clicked")}
          />
          <Button
            type="secondary"
            label="Cancel"
            onClick={handleNavigateBack}
          />
        </div> */}
      </div>
      <Paper className="mt-7">
        <Table columns={columns} data={data} />
      </Paper>
    </div>
  );
};

export default AllUser;
