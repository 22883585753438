import PropTypes from "prop-types";

const Paper = ({ children, className }) => {
  return (
    <div className={`bg-white rounded-xl border-gray-300 p-4 ${className}`}>
      {children}
    </div>
  );
};

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Paper.defaultProps = {
  className: "",
};

export default Paper;
