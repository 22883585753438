// src/components/PrivateRoute.jsx
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Component }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? <Component /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
