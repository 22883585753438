import PropTypes from "prop-types";

const getBorderColor = (stageName) => {
  switch (stageName) {
    case "Lead Stage":
      return "border-lead-stage";
    case "Prospect Stage":
      return "border-prospect-stage";
    case "Account Stage":
      return "border-account-stage";
    default:
      return "";
  }
};

const getTextColor = (stageName) => {
  switch (stageName) {
    case "Lead Stage":
      return "text-lead-stage";
    case "Prospect Stage":
      return "text-prospect-stage";
    case "Account Stage":
      return "text-account-stage";
    default:
      return "text-primary-black";
  }
};

const Card = ({
  institution,
  institutionImg,
  institutionName,
  stageName,
  openedEnquiries = institution?.openedcount,
  confirmEnquiries = institution?.confirmedcount,
  closedcEnquiries = institution?.closedcount,
  cancelEnquiries = institution?.cancelledcount,
  created_on = "",
}) => {
  const borderColorClass = getBorderColor(stageName);
  const textColorClass = getTextColor(stageName);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  return (
    <div
      className={`card bg-white max-w-sm rounded overflow-hidden shadow-lg p-4 transform transition duration-300 hover:scale-105 hover:shadow-custom ${borderColorClass}`}
      style={{ borderBottomWidth: "4px" }}
    >
      <div className="flex flex-col sm:flex-row gap-4">
        <img
          className="w-20 h-20 md:w-20 md:h-20 lg:w-20 lg:h-20 object-cover"
          src={institutionImg}
          alt={institutionName}
        />

        <div>
          <p className={`text-[14px] font-medium ${textColorClass}`}>
            {stageName}
          </p>
          <div className="font-bold text-[16px] mb-2 text-[#121212]">
            {institutionName}
          </div>
        </div>
      </div>
      <hr className="my-2" />
      {stageName === "Account Stage" || stageName === "Prospect Stage" ? (
        <div className="px-2 pt-2 pb-2">
          <p className="text-primary-black flex justify-between text-[14px]">
            <span>Opened Enquiries:</span>
            <span className="font-semibold">{openedEnquiries}</span>
          </p>{" "}
          <p className="text-primary-black flex justify-between text-[14px]">
            <span>Confirmed Enquiries:</span>
            <span className="font-semibold">{confirmEnquiries}</span>
          </p>
          <p className="text-primary-black flex justify-between text-[14px]">
            <span>Closed Enquiries:</span>
            <span className="font-semibold">{closedcEnquiries}</span>
          </p>
          <p className="text-primary-black flex justify-between text-[14px]">
            <span>Cancelled Enquiries:</span>
            <span className="font-semibold">{cancelEnquiries}</span>
          </p>
        </div>
      ) : (
        <div className="px-2 pt-2 pb-2">
          <p className="text-primary-black flex justify-between text-[14px]">
            <span>Lead Created On:</span>
            <span className="font-semibold">{formatDate(created_on)}</span>
          </p>
          {[...Array(3)].map((_, index) => (
            <p
              key={index}
              className="text-primary-black flex justify-between text-[14px] none"
            >
              <span>-</span>
              <span className="font-semibold">{"-"}</span>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  institutionImg: PropTypes.string.isRequired,
  institutionName: PropTypes.string.isRequired,
  stageName: PropTypes.string.isRequired,
  openedEnquiries: PropTypes.number,
  cancelledEnquiries: PropTypes.number,
  closedcEnquiries: PropTypes.number,
  confirmEnquiries: PropTypes.number,
  created_on: PropTypes.string,
};

export default Card;
