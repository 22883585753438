import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

const apiCall = async (method, endpoint, data = null, params = {}) => {
  const config = {
    method,
    url: `${BASE_URL}${endpoint}`,
    data,
    params,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const get = (endpoint, params = {}) =>
  apiCall("get", endpoint, null, params);
export const post = (endpoint, data) => apiCall("post", endpoint, data);
export const put = (endpoint, data) => apiCall("put", endpoint, data);
export const del = (endpoint) => apiCall("delete", endpoint);
