import React from "react";

const ProfileCard = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    return <div>Please Logout and login if you can't see any datas</div>;
  }

  return (
    <div className="min-h-screen p-6">
      <div className="w-full mx-auto">
        <div className="flex justify-between mb-6">
          <div>
            <h2 className="text-primary-black text-2xl font-semibold">
              Profile
            </h2>
            <p className="text-[14px]">
              <span>My Profile</span>
            </p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5  gap-6 items-center">
              <div className="md:col-span-1 flex justify-center md:justify-start">
                {user?.profilephoto ? (
                  <img
                    className="rounded-full border-4 border-white w-32 h-32"
                    src={user.profilephoto}
                    alt="Profile"
                  />
                ) : (
                  <span className="material-symbols-outlined w-32 h-32 rounded-full flex items-center justify-center bg-gray-200 text-7xl">
                    person
                  </span>
                )}
              </div>
              <div className="md:col-span-4">
                <h2 className="text-3xl font-semibold text-gray-800">
                  {user?.username || "N/A"}
                </h2>
                <p className="text-gray-500">{user?.role}</p>
                <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-4">
                  <div className="mt-7 items-center justify-center">
                    <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                      mail
                    </span>
                    <div className="mt-2">
                      <p className="text-[14px] leading-5 text-gray-500">
                        {user?.email || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="mt-7">
                    <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                      call
                    </span>
                    <div className="mt-2">
                      <p className="text-[14px] leading-5 text-gray-500">
                        {user?.mobilenumber || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="mt-7">
                    <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                      location_on
                    </span>
                    <div className="mt-2">
                      <p className="text-[14px] leading-5 text-gray-500">
                        {user?.assigncity || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="mt-7">
                    <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                      person_add
                    </span>
                    <div className="mt-2">
                      <p className="text-[14px] leading-5 text-gray-500">
                        {user?.role || "N/A"}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {user?.role !== "Admin" && (
              <div className="mt-7">
                <h3 className="text-lg font-semibold text-gray-800 mb-7">
                  Activities
                </h3>
                <div className="grid w-full lg:w-3/4 h-full grid-cols-4 gap-4">
                  <div className="col-span-1">
                    <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                      <p className="text-[20px] font-semibold">
                        {user?.openedcount}
                      </p>
                      <p className="text-[16px] font-normal">Opened Enquiry</p>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                      <p className="text-[20px] font-semibold">
                        {user?.confirmedcount}
                      </p>
                      <p className="text-[16px] font-normal">
                        Confirmed Enquiry
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                      <p className="text-[20px] font-semibold">
                        {user?.closedcount}
                      </p>
                      <p className="text-[16px] font-normal">Closed Enquiry</p>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                      <p className="text-[20px] font-semibold">
                        {user?.cancelledcount}
                      </p>
                      <p className="text-[16px] font-normal">
                        Canceled Enquiry
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
