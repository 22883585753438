// src/components/Login.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginBg from "../assets/img/login-bg.svg";
import InputText from "../customComponents/InputText";
import Password from "../customComponents/Password";
import Loginsmall from "../assets/img/login-mob.svg";
import { post } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = { email, password };
      const response = await post("/loginAdmin", payload);

      if (response.status) {
        localStorage.setItem("user", JSON.stringify(response.data));
        navigate("/institutions");
      } else {
        console.error("Invalid User:", response.message);
        setErrorMessage(response.message);
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setErrorMessage("An error occurred. Please try again.");
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 bg-white login-div overflow-auto md:overflow-auto lg:overflow-auto">
      <div className="hidden lg:block h-screen">
        <img
          src={LoginBg}
          className="rounded rounded-r-2xl w-full h-full object-cover"
          alt="Login background"
        />
      </div>
      <div className="lg:hidden">
        <img
          src={Loginsmall}
          className="w-full h-full object-cover"
          alt="Login background"
        />
      </div>
      <div>
        <div className="mt-12 md:mt-16 flex flex-col items-center justify-center mb-6">
          <img
            src="https://crm.fuelifeexplorers.com/crmImages/logo/1680004129Fuelife-Explorers.png"
            className="react h-[72px]"
            alt="React logo"
          />
        </div>
        <div className="px-8 sm:px-6 md:px-40 lg:px-24 xl:px-28 text-center">
          <div className="text-center mb-10"></div>
          <h2 className="text-red-600 text-[20px] leading-8 font-semibold ">
            Welcome back !
          </h2>
          <h1 className=" text-[#121212] text-[14px] md:text-[22px] font-semibold mb-4">
            Login to your Account
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="text-left">
              <label className=" text-[14px] md:text-[16px] font-semibold mb-4 ">
                Email
              </label>
              <InputText
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className=" text-[14px] md:text-[16px] font-semibold mb-4">
                Password
              </label>
              <Password
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm border-b-[#797979]"
              />
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-between mb-6">
              <label className="flex items-center mb-2 sm:mb-0">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="mt-5"
                />
                <span className="ml-2 text-gray-700">Remember me</span>
              </label>
              <a
                href="/forgot-password"
                className="text-blue-600 hover:underline  text-[14px] md:text-[16px] font-semibold"
              >
                Forgot password?
              </a>
            </div>
            <button
              type="submit"
              className="w-56 bg-red-500 text-white py-2 rounded-3xl shadow-lg hover:bg-red-600"
            >
              Login
            </button>
          </form>
          {errorMessage && (
            <div className="text-red-500 mt-4">{errorMessage}</div>
          )}
          <div className="text-center mt-20 md:mt-20 lg:mt-14  mb-4">
            <p className="text-[#797979] text-[14px]">
              Copyright @ Fuelife 2024
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
